
@import '../../styles/customMediaQueries.css';

.customField {
  margin-bottom: 24px!important;

  @media (--viewportMedium) {
    margin-bottom: 32px!important;
  }

}



.red {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
